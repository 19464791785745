import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Display the correct date without being affected by time zone differences.
 * @param date
 * @returns {number}
 */
export const normalizeDate = date => date.getTime() - date.getTimezoneOffset() * -60000

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Boolean} normalize
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, normalize = false, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value

  let date = new Date(value)

  if (normalize) {
    date = new Date(normalizeDate(date))
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(date)
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateString = dateString => {
  const date = new Date(dateString)
  return new Date(normalizeDate(date)).toLocaleDateString()
}

export const formatTimeString = timeString => new Date(`1970-01-01T${timeString}`).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const formatGameDateAndTime = (date = null, time = null) => {
  const tbd = 'TBD'
  const formatted = {
    date: tbd,
    time: tbd,
  }

  if (date) {
    formatted.date = formatDate(date, true)
  }

  if (time) {
    formatted.time = formatTimeString(time)
  }

  return formatted
}

// Define a custom function to compare dates
export const compareDates = (a, b) => {
  const dateA = new Date(a)
  const dateB = new Date(b)

  return dateA - dateB
}

// Extend the Array prototype with a custom sortByDate method
// eslint-disable-next-line no-extend-native,func-names
Array.prototype.sortByDate = function (order = 'asc', key = 'date') {
  if (order.toLowerCase() === 'asc') {
    return this.sort((a, b) => compareDates(a[key], b[key]))
  }

  if (order.toLowerCase() === 'desc') {
    return this.sort((a, b) => compareDates(b[key], a[key]))
  }

  throw new Error('Invalid order. Use "asc" or "desc".')
}
