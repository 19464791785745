<template>
  <b-card :title="title || `Delete ${itemNameTitleCase}`">
    <b-alert
      show
      variant="warning"
      class="mb-2"
    >
      <h2 class="alert-heading">
        {{ alertHeading || `Are you sure you want to delete this ${itemNameLowerCase}?` }}
      </h2>

      <div class="alert-body">
        <p>
          {{ alertBody || `Once you delete this ${itemNameLowerCase}, past records will be permanently deleted. This action cannot be undone.` }}
        </p>
      </div>
    </b-alert>

    <b-button
      variant="danger"
      @click="$emit('delete')"
    >
      {{ buttonText || `Delete ${itemNameTitleCase}` }}
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BAlert, BButton } from 'bootstrap-vue'

import { title as titleCase } from '@/@core/utils/filter'

export default {
  components: {
    BCard,
    BAlert,
    BButton,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    alertHeading: {
      type: String,
      default: null,
    },
    alertBody: {
      type: String,
      default: null,
    },
    itemName: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
  },
  computed: {
    itemNameLowerCase() {
      return this.itemName.toLowerCase()
    },
    itemNameTitleCase() {
      return titleCase(this.itemName)
    },
  },
}
</script>

<style scoped>

</style>
